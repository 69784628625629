import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { AlertModal } from "../../components/Common/AlertModal";

export const useErrorHandler = () => {
  const { t } = useTranslation("message");

  // 반환 타입을 명시적으로 지정
  const isErrorWithMessage = (error: unknown): string => {
    if (
      typeof error === "object" &&
      error !== null &&
      "message" in error &&
      typeof (error as Record<string, unknown>).message === "string"
    ) {
      return (error as Record<string, unknown>).message as string;
    } else {
      return "";
    }
  };

  const getErrorMessage = (error: AxiosError) => {
    let message: string = "";
    if (error.response) {
      message = isErrorWithMessage(error.response.data);

      if (message === "" || message === "[object Object]") {
        switch (error.response.status) {
          case 401:
            message = t("Authentication has expired; re-login is required");
            AlertModal("msg", message);
            localStorage.clear();
            break;
          case 400:
            message = t("Data input is incorrect");
            break;
          case 403:
            message = t("Access denied");
            break;
          case 404:
            message = t("We couldn't find the result you were looking for");
            break;
          case 405:
            message = t("405: Access denied");
            break;
          case 500:
            message = t("A server error has occurred");
            break;
          default:
            message = t(
              "An unknown error has occurred. Please contact the administrator."
            );
            break;
        }
      }
    } else {
      message = t("An error has occurred. Please contact the administrator");
    }
    return message; // 명시적으로 message 반환
  };

  return { getErrorMessage };
};
