import { CommentInfo } from "../Common/Comment";
import { PreviousSalesActivityInfo } from "../DashBoard/Sales";
import { EmployeeInfo } from "../Employee/Employee";
import { SalesType } from "../Lead/salesActivity";

export enum SalesActivityConditionKey {
  ALL = "all",
  TODAY = "todayActivities",
  FUTURE = "futureActivities",
  PAST = "pastActivities",
}

export enum SalesActivitySortStatus {
  DESC = "Descending",
  ASC = "Ascending",
}

export enum SalesStatus {
  AWAITING_INPUT = "awaitingInput",
  DELAYED = "delayed",
  INPROGRESS = "inProgress",
  COMPLETED = "completed",
  CANCELD = "canceled",
}
export interface SalesActivityStatusInfo {
  key: SalesActivityConditionKey;
  title: string;
}

export interface SalesActivityListInfo {
  currentPage: number;
  nextPage: number;
  salesActivities: PreviousSalesActivityInfo[];
  totalElements: number;
  totalPages: number;
}

export interface SalesActivityInfo {
  id: number | null;
  priority: number;
  type: SalesType | "";
  status: SalesStatus;
  title: string;
  content: string;
  nextAction: string;
  activityDate: string | null;
  followUpDate: string | null;
  isChecked: boolean;
  customerId?: number;
}
export interface NextSalesActivityInfo extends PreviousSalesActivityInfo {}
export type SalesActivityStatusInfoList = SalesActivityStatusInfo[];
export interface SalesActivityDetailInfo {
  id: number | null;
  priority: number;
  type: SalesType | "";
  status: SalesStatus;
  title: string;
  content: string;
  nextAction: string;
  activityDate: string | null;
  followUpDate: string | null;
  isChecked: boolean;
  customerId?: number;
  currentCustomerStep?: { id: number; name: string };
  previousActivity?: PreviousSalesActivityInfo | null;
  nextActivity?: NextSalesActivityInfo | null;
  createdAt: string;
  updataedAt: string;
  createBy: EmployeeInfo;
  comments: CommentInfo[];
}
