import DOMPurify from "dompurify";

export const useDOMParser = () => {
  const parseHTMLString = (htmlString: string): string => {
    // HTML 문자열 정리
    const sanitizedHTML = DOMPurify.sanitize(htmlString, {
      ALLOWED_TAGS: [
        "img",
        "div",
        "p",
        "span",
        "br",
        "a",
        "strong",
        "em",
        "table",
        "thead",
        "tbody",
        "tfoot",
        "tr",
        "th",
        "td",
      ],
      ALLOWED_ATTR: [
        "src",
        "class",
        "alt",
        "href",
        "title",
        "target",
        "rel",
        "colspan",
        "rowspan",
        "scope",
        "width",
        "height",
        "className",
        "color",
        "background-color",
      ],
      USE_PROFILES: { html: true },
    });

    return sanitizedHTML;
  };

  const parseHTMLContentString = (htmlString: string): string => {
    // 정리된 HTML
    const sanitizedHTML = parseHTMLString(htmlString);

    // DOMParser로 HTML 파싱
    const parser = new DOMParser();
    const doc = parser.parseFromString(sanitizedHTML, "text/html");

    // 모든 <h3> 태그 검색
    const headers = Array.from(doc.querySelectorAll("h3")); // NodeList를 배열로 변환
    const contentHeader = headers.find(
      (header) => header.textContent?.trim() === "Content"
    ) as HTMLElement | null;

    if (!contentHeader) return sanitizedHTML; // "Content" 제목을 가진 <h3> 태그가 없으면 빈 문자열 반환

    // 다음 형제 요소 가져오기
    const contentArea = contentHeader.nextElementSibling as HTMLElement | null; // 타입 단언 추가
    return contentArea && contentArea.textContent
      ? contentArea.textContent.trim()
      : ""; // 안전한 접근
  };

  return {
    parseHTMLString,
    parseHTMLContentString,
  };
};
