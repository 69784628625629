import { atom } from "recoil";
import {
  InitGroupInfo,
  InitListSearchInfo,
  InitMyInfo,
} from "./types/initObject";
import { NotificationInfo } from "./types/Notification";
import { FilterObject } from "../types/Lead/List";
import { GroupInfo } from "../types/Group/group";

export const leftDrawerOpenState = atom({
  key: "leftDrawerOpenState",
  default: false,
});

export const loginState = atom({
  key: "loginState",
  default: true,
});

export const passwordState = atom({
  key: "passwordState",
  default: false,
});

export const LoadingAtom = atom({
  key: "LoadingAtom",
  default: false,
});

export const loginAccountState = atom({
  key: "loginAccountState",
  default: "",
});

/**삭제할 내용 */
export const MyInfoState = atom({
  key: "MyInfoState",
  default: InitMyInfo,
});

export const BoardPageNumber = atom({
  key: "BoardPageNumber",
  default: 1,
});

export const ManagedGroupDataState = atom<GroupInfo[]>({
  key: "ManagedGroupDataState",
  default: [],
});

export const MyBranchDataState = atom({
  key: "MyBranchDataState",
  default: InitGroupInfo,
});

export const registerLeadStateAtom = atom({
  key: "registerLeadStateAtom",
  default: "",
});

export const SearchDataAtom = atom({
  key: "SearchDataAtom",
  default: InitListSearchInfo,
});

export const NotiDataState = atom<NotificationInfo[]>({
  key: "NotiDataState",
  default: [],
});

export const NotiIdState = atom<string[]>({
  key: "NotiIdState",
  default: [],
});

export const ListTypeState = atom<string>({
  key: "ListTypeState",
  default: "list",
});

export const FilterObjects = atom<FilterObject[]>({
  key: "FilterObjects",
  default: [],
});

export const FilterDropdownData = atom<Record<string, any[]>>({
  key: "FilterDropdownData",
  default: {},
});
