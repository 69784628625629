import { lazy, Suspense, useCallback, useEffect, useMemo } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Content from "../pages/Layout/Content";
import Header from "../pages/Layout/Header";
import { Urls } from "./Urls";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  ManagedGroupDataState,
  MyInfoState,
  NotiDataState,
  NotiIdState,
  loginState,
  passwordState,
} from "../system/atoms";
import base64 from "base-64";

import LoadingSpinnerModal from "../components/Common/Loading";
import { AlertModal } from "../components/Common/AlertModal";
import { EmployeeApi } from "../system/api/employeeApi";

import { onMessageListener, requestPermission } from "../firebase";
import {
  NotificationInfo,
  RecievedNotiInfo,
} from "../system/types/Notification";

import { EmployeeRole } from "../types/Employee/Employee";
import { useErrorHandler } from "../hooks/Common/useErrorHandler";

// pages
import LandingMain from "../pages/Main/LandingMain";
import PasswordPage from "../pages/Main/PasswordPage";
import NotFoundPage from "../pages/Layout/NotFoundPage";
import { AxiosError } from "axios";
import { GroupApi } from "../api/Group/groupApi";
import { GroupInfo } from "../types/Group/group";

const AdminInformationPage = lazy(
  () => import("../pages/Admin/Information/AdminInformationPage")
);

const MyCommentPage = lazy(
  () => import("../pages/List/MyComment/MyCommentPage")
);
const NoticePage = lazy(() => import("../pages/Notice/NoticePage"));
const NoticeDetailPage = lazy(() => import("../pages/Notice/NoticeDetailPage"));
const NoticeAddPage = lazy(() => import("../pages/Notice/NoticeAddPage"));
const NoticeUpdatePage = lazy(() => import("../pages/Notice/NoticeUpdatePage"));

const QnaPage = lazy(() => import("../pages/Qna/QnaPage"));
const QnaDetailPage = lazy(() => import("../pages/Qna/QnaDetailPage"));
const QnaAddPage = lazy(() => import("../pages/Qna/QnaAddPage"));
const QnaUpdatePage = lazy(() => import("../pages/Qna/QnaUpdatePage"));

const MyInfoPage = lazy(() => import("../pages/Setting/MyInfoPage"));
const LeadPage = lazy(() => import("../pages/List/Lead/LeadPage"));
const ProductRegisterPage = lazy(
  () => import("../pages/Admin/ProductRegister/ProductRegisterPage")
);
const ByCaseRegisterPage = lazy(
  () => import("../pages/Register/ByCase/ByCasePage")
);
const LeadDetailPage = lazy(
  () => import("../pages/Register/ByCase/LeadDetailPage")
);

const SalesActivityListPage = lazy(
  () => import("../pages/SalesActivity/SalesActivityListPage")
);

const IntermediaryListPage = lazy(
  () => import("../pages/Intermediary/IntermediaryListPage")
);
const IntermediaryDetailPage = lazy(
  () => import("../pages/Intermediary/IntermediaryDetailPage")
);
const IntermediaryAddPage = lazy(
  () => import("../pages/Intermediary/IntermediaryAddPage")
);
const IntermediaryUpdatePage = lazy(
  () => import("../pages/Intermediary/IntermediaryUpdatePage")
);

const NotiMainPage = lazy(() => import("../pages/List/Noti/NotiMain"));

const QAQCMain = lazy(() => import("../pages/QAQC/QAQCMain"));
const AdminSalesProtectionMain = lazy(
  () => import("../pages/Admin/BusinessProtection/AdminSalesProtectionMain")
);
const ScheduleMainPage = lazy(
  () => import("../pages/Schedule/ScheduleMainPage")
);
const DahsBoardPage = lazy(() => import("../pages/DashBoard/DashBoardPage"));
const ReportPage = lazy(() => import("../pages/Report/ReportPage"));
const AdminPermissionPage = lazy(
  () => import("../pages/Admin/Permission/AdminPermissionPage")
);

// 공통으로 라우트 될 항목
const commonRoutes = [
  { path: Urls.dashBoard, component: DahsBoardPage, exact: true },
  { path: Urls.leadList, component: LeadPage, exact: true },
  { path: Urls.myComment, component: MyCommentPage, exact: true },
  { path: Urls.noti, component: NotiMainPage, exact: true },
  { path: Urls.leadDetail, component: LeadDetailPage, exact: true },
  { path: Urls.salesActivity, component: SalesActivityListPage, exact: true },
  { path: Urls.intermediary, component: IntermediaryListPage, exact: true },
  {
    path: Urls.intermediaryDetail,
    component: IntermediaryDetailPage,
    exact: true,
  },
  {
    path: Urls.intermediaryAdd,
    component: IntermediaryAddPage,
    exact: true,
  },
  {
    path: Urls.intermediaryUpdate,
    component: IntermediaryUpdatePage,
    exact: true,
  },
  { path: Urls.registerByCase, component: ByCaseRegisterPage, exact: true },
  { path: Urls.notice, component: NoticePage, exact: true },
  { path: Urls.noticeDetail, component: NoticeDetailPage, exact: true },
  { path: Urls.noticeAdd, component: NoticeAddPage, exact: true },
  { path: Urls.noticeUpdate, component: NoticeUpdatePage, exact: true },
  { path: Urls.qna, component: QnaPage, exact: true },
  { path: Urls.qnaDetail, component: QnaDetailPage, exact: true },
  { path: Urls.qnaAdd, component: QnaAddPage, exact: true },
  { path: Urls.qnaUpdate, component: QnaUpdatePage, exact: true },
  { path: Urls.schedule, component: ScheduleMainPage, exact: true },
  { path: Urls.report, component: ReportPage, exact: true },
  { path: Urls.qaqc, component: QAQCMain, exact: true },
  { path: Urls.myInfo, component: MyInfoPage, exact: true },
];

// MANAGER일 때 라우트 될 항목
const managerRoutes = [
  {
    path: Urls.adminPermission,
    component: AdminPermissionPage,
    exact: true,
  },
];

// ADMIN일 때 라우트 될 항목
const adminRoutes = [
  {
    path: Urls.adminPermission,
    component: AdminPermissionPage,
    exact: true,
  },
  {
    path: Urls.adminInformation,
    component: AdminInformationPage,
    exact: true,
  },
  {
    path: Urls.adminProductRegister,
    component: ProductRegisterPage,
    exact: true,
  },
  {
    path: Urls.adminSalesProtection,
    component: AdminSalesProtectionMain,
    exact: true,
  },
];

const Routes = () => {
  const loginPageState = useRecoilValue(loginState);
  const passwordPageState = useRecoilValue(passwordState);
  const [myInfoData, setMyInfoData] = useRecoilState(MyInfoState);
  const [LoginStatus, setLoginStatus] = useRecoilState(loginState);
  const [ManagedGroupData, setManagedGroupData] = useRecoilState(
    ManagedGroupDataState
  );
  const [, setNotificationData] = useRecoilState(NotiDataState);
  const [, setNotifiIdData] = useRecoilState(NotiIdState);
  const history = useHistory();
  const { getErrorMessage } = useErrorHandler();
  // token 있으면
  const existedToken = localStorage.getItem("accessToken");
  const existedRefreshToken = localStorage.getItem("refreshToken");

  const getManagedGroupInfo = useCallback(async () => {
    try {
      const { data } = await GroupApi.getManagedGroupInfo<GroupInfo[]>();
      setManagedGroupData(data);
    } catch (err) {
      const msg = getErrorMessage(err as AxiosError);
      AlertModal("msg", msg);
    }
  }, []);

  useEffect(() => {
    if (existedToken) {
      if (LoginStatus && !myInfoData.email) {
        EmployeeApi.getMyInfo()
          .then((res) => {
            setMyInfoData(res.data);
          })
          .catch((err) => {
            const msg = getErrorMessage(err);
            AlertModal("msg", msg);
          });
      } else if (
        LoginStatus &&
        myInfoData.email &&
        (!myInfoData.phone ||
          !myInfoData.jobPosition?.id ||
          !myInfoData.jobTitle?.id)
      ) {
        history.replace(`/myInfo`);
      }
    } else {
      setLoginStatus(false);
      history.replace(`/login`);
    }
  }, [
    existedToken,
    LoginStatus,
    setMyInfoData,
    myInfoData.email,
    myInfoData.phone,
    myInfoData.jobPosition,
    myInfoData.jobTitle,
    history,
    setLoginStatus,
  ]);

  useEffect(() => {
    if (existedToken) {
      if (ManagedGroupData.length === 0) {
        getManagedGroupInfo();
      }
    }
  }, [
    existedToken,
    ManagedGroupData.length,
    setManagedGroupData,
    getManagedGroupInfo,
  ]);

  useEffect(() => {
    if (existedToken) {
      const fetchData = async () => {
        await requestPermission();
        onMessageListener((payload: RecievedNotiInfo) => {
          const newItem: NotificationInfo = {
            messageId: payload?.data?.messageId,
            type: payload?.data?.type,
            title: payload?.notification?.title,
            body: payload?.notification?.body,
            path: payload?.data?.path,
            createdAt: payload?.data?.createdAt,
          };
          setNotificationData((prevData) => [...prevData, newItem]);
          setNotifiIdData((prevData) => [
            ...prevData,
            payload?.data?.messageId,
          ]);
        });
      };

      fetchData();
    }
  }, [setNotificationData, setNotifiIdData, existedToken]);

  useEffect(() => {
    const currentTime = new Date().getTime() / 1000;
    if (existedRefreshToken) {
      const decodeRefreshToken = base64.decode(
        existedRefreshToken.substring(
          existedRefreshToken.indexOf(".") + 1,
          existedRefreshToken.lastIndexOf(".")
        )
      );
      const parsedJson = JSON.parse(decodeRefreshToken);
      const exp = parsedJson.exp;
      if (exp < currentTime) {
        AlertModal("msg", "인증이 만료되어 재로그인이 필요합니다.");
        localStorage.clear();
      }
    }
  }, []);

  // role에 따라 나타날 route 스위칭
  const allRoutes = useMemo(() => {
    switch (myInfoData.role) {
      case EmployeeRole.ADMIN:
        return [...commonRoutes, ...adminRoutes];
      case EmployeeRole.MANAGER:
        return [...commonRoutes, ...managerRoutes];
      default:
        return [...commonRoutes];
    }
  }, [myInfoData.role]);

  return (
    <Switch>
      <Route path={Urls.login} component={LandingMain} exact></Route>

      {passwordPageState && (
        <Route path={Urls.password} component={PasswordPage} exact></Route>
      )}

      {passwordPageState === false && loginPageState && existedToken && (
        <>
          <LoadingSpinnerModal />,{/* Header - Navigation Menu */}
          <Header />
          {/* Body - Content */}
          <Content>
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                {allRoutes.map(({ path, component, exact }) => (
                  <Route
                    key={path}
                    path={path}
                    component={component}
                    exact={exact}
                  />
                ))}

                {/* 404 Not Found */}
                <Route path="/*" component={NotFoundPage}></Route>
              </Switch>
            </Suspense>
          </Content>
        </>
      )}
    </Switch>
  );
};

export default Routes;
