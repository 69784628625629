import { styled } from "@mui/material/styles";
import {
  PRIMARY,
  PRIMARYDISABLED,
  PRIMARYHOVERED,
  PRIMARYPRESSED,
  REDDISABLED,
} from "./Color";
import { Grid } from "@mui/material";

export const CustomButtonPrimary = styled(Grid)({
  backgroundColor: `${PRIMARY}`,
  color: "#ffffff",
  border: "1px solid #DCDFE3",
  boxShadow: "none",
  borderRadius: "8px",
  width: "100%",
  height: "3rem",
  margin: "10px 0px",
  cursor: "pointer",
  zIndex: "999",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: `${PRIMARYHOVERED}`,
    color: "#ffffff",
  },
  "&:active": {
    backgroundColor: `${PRIMARYPRESSED}`,
    color: "#ffffff",
  },
});

export const CustomButtonDisabledFullWidth = styled(Grid)({
  backgroundColor: `${PRIMARYDISABLED}`,
  color: "#ffffff",
  border: "1px solid #DCDFE3",
  boxShadow: "none",
  borderRadius: "8px",
  width: "100%",
  height: "3rem",
  margin: "10px 0px",
  cursor: "default",
  zIndex: "999",
  overflow: "hidden",
  whiteSpace: "nowrap",
});

export const CustomButtonCommon = styled(Grid)({
  backgroundColor: `#ffffff`,
  color: "#4B4F5A",
  border: "1px solid #DCDFE3",
  boxShadow: "none",
  borderRadius: "8px",
  width: "100%",
  height: "3rem",
  margin: "10px 0px",
  cursor: "pointer",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: `#F4F4F4`,
    color: "#4B4F5A",
  },
});

export const CustomButtonDisabled = styled(Grid)({
  backgroundColor: PRIMARYDISABLED,
  color: "#ffffff",
  border: "0px",
  boxShadow: "none",
  borderRadius: "5px",
  width: "7rem",
  height: "2.5rem",
  cursor: "default",
  fontWeight: 600,
  overflow: "hidden",
  whiteSpace: "nowrap",
});

export const CustomButtonPrimary2 = styled("button")({
  backgroundColor: `${PRIMARY}`,
  color: "#ffffff",
  boxShadow: "none",
  borderRadius: "5px",
  width: "7rem",
  height: "2.5rem",
  cursor: "pointer",
  fontWeight: 600,
  border: "0px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: `${PRIMARYHOVERED}`,
    boxShadow: "none",
    color: "#ffffff",
  },
});

export const CustomButtonDisabled2 = styled(Grid)({
  backgroundColor: REDDISABLED,
  color: "#ffffff",
  border: "0px",
  boxShadow: "none",
  borderRadius: "5px",
  width: "7rem",
  height: "2.5rem",
  cursor: "default",
  fontWeight: 600,
  overflow: "hidden",
  whiteSpace: "nowrap",
});

export const CustomButtonSmallYellow = styled("button")({
  backgroundColor: "#fff9c4",
  color: "#F59210",
  boxShadow: "none",
  borderRadius: "5px",
  width: "6rem",
  height: "2rem",
  cursor: "pointer",
  fontWeight: 600,
  border: "0px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#fff59d",
    boxShadow: "none",
    color: "#F59210",
  },
});

export const CustomButtonSmallRed = styled("button")({
  backgroundColor: `#FFF0F3`,
  color: "#F25C75",
  boxShadow: "none",
  borderRadius: "5px",
  cursor: "pointer",
  fontWeight: 600,
  width: "6rem",
  height: "2rem",
  border: "0px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#FFE6EB",
    boxShadow: "none",
    color: "#F25C75",
  },
});

export const CustomButtonSmallCommon = styled("button")({
  backgroundColor: `#ffffff`,
  color: "#4B4F5A",
  border: "1px solid #DCDFE3",
  boxShadow: "none",
  borderRadius: "5px",
  cursor: "pointer",
  fontWeight: 600,
  width: "6rem",
  height: "2rem",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: `#F7F8FA`,
    color: "#4B4F5A",
  },
});
