import { GroupInfo, JobInfo } from "../Group/group";

export enum EmployeeRole {
  USER = "USER",
  MANAGER = "MANAGER",
  ADMIN = "ADMIN",
  QAQC = "QAQC",
  CS = "CS",
}

export interface LoginInfo {
  email: string;
  password: string;
}

export interface PasswordInfo {
  oldPassword: string;
  newPassword: string;
}

export interface EmployeeInfo {
  id?: number | null;
  email: string;
  name: string;
  phone: string;
  picture: string | null;
  groupId?: number | null;
  groupName?: string;
  jobTitle: JobInfo;
  jobPosition: JobInfo;
  isResignation?: boolean;
  isSales?: boolean;
  enabled?: boolean;
  role?: EmployeeRole;
  groups?: GroupInfo[];
  timeZone?: string;
  group?: string;
}

export interface EmployeeInfoWithAchievement extends EmployeeInfo {
  goalId: number;
  goalIsSet: boolean;
  salesAmount: number;
  newLeadsInbound: number;
  newLeadsOutbound: number;
  inboundLeadConversion: number;
  outboundLeadConversion: number;
}

export interface EmployeeTableInfo {
  id: number | null;
  email: string;
  name: string;
  phone: string;
  picture: string;
  isResignation: boolean | undefined;
  group?: string;
  jobTitle: JobInfo;
  jobPosition: JobInfo;
}

export interface MyInfo {
  id: number | null;
  email: string;
  name: string;
  phone: string;
  picture: string;
  isSales?: boolean;
  isResignation?: boolean;
  enabled?: boolean;
  role?: EmployeeRole;
  groups: GroupInfo[];
  jobTitle: JobInfo;
  jobPosition: JobInfo;
  timeZone: string;
}

export type GetAllEmployeeListResponse = EmployeeInfo[];
