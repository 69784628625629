import dayjs, { Dayjs, ManipulateType } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
export const getThreeMonthStartAndEndDate = (date: string | Dayjs) => {
  const startDate = dayjs(date).startOf("month").format("YYYY-MM-DD");
  const endDate = dayjs(date)
    .add(2, "month")
    .endOf("month")
    .format("YYYY-MM-DD");
  return {
    startDate,
    endDate,
  };
};

export const calculateDate = (
  date: string | Dayjs,
  status: "prev" | "next",
  step: number,
  unit: ManipulateType
) => {
  const stdDate = dayjs(date);

  switch (status) {
    case "prev":
      return stdDate.subtract(step, unit).format("YYYY-MM-DD");
    case "next":
      return stdDate.add(step, unit).format("YYYY-MM-DD");
    default:
      return stdDate.format("YYYY-MM-DD");
  }
};

export const makeMonthColumnList = (
  year: string,
  month: string,
  range: string
) => {
  const startDate = dayjs(`${year}-${month}-01`).startOf("month"); // 시작 연도와 월의 첫 날

  const monthList = Array.from({ length: Number(range) }, (_, idx) => {
    const currentMonth = startDate.add(idx, "month"); // 시작 날짜에 idx 개월을 추가
    return {
      key: `month${currentMonth.format("MM")}`, // `monthMM` 형식으로 설정
      value: currentMonth.format("YYYY-MM"), // "YYYY-MM" 형식으로 설정
    };
  });

  return monthList;
};

// 범위 안의 날짜인지 체크
export const checkInRangeDate = (
  startDate: string | Dayjs,
  endDate: string | Dayjs,
  targetDate: string | Dayjs
) => {
  const minDate = dayjs(startDate);
  const maxDate = dayjs(endDate);
  const stdDate = dayjs(targetDate);

  if (stdDate.isBefore(minDate) || stdDate.isAfter(maxDate)) return false;
  else return true;
};

// DDay계산
export const calculateDDay = (targetDate: string | null): string => {
  if (targetDate === null) return "";
  const today = dayjs().startOf("day");
  const target = dayjs(targetDate).startOf("day");

  const diff = target.diff(today, "day");

  return diff === 0 ? "D-0" : diff > 0 ? `D-${diff}` : `D+${Math.abs(diff)}`;
};

export const formatDateString = (date: string | Date | Dayjs | null) => {
  if (!date) return "";
  return dayjs(date).format("YYYY-MM-DD");
};

// utc -> local
export const utcToLocal = (
  utcTime: string,
  timeZone: string = dayjs.tz.guess()
): string => {
  return dayjs.utc(utcTime).tz(timeZone).format("YYYY-MM-DD HH:mm:ss");
};

export const utcToLocalDate = (
  utcTime: string,
  timeZone: string = dayjs.tz.guess()
): string => {
  return dayjs.utc(utcTime).tz(timeZone).format("YYYY-MM-DD");
};

export const utcToLocalTime = (
  utcTime: string,
  timeZone: string = dayjs.tz.guess()
): string => {
  return dayjs.utc(utcTime).tz(timeZone).format("HH:mm:ss");
};

// local -> utc
export const localToUtc = (
  localTime: string | null,
  timeZone: string = dayjs.tz.guess()
): string => {
  if (localTime === null) return "";
  return dayjs.tz(localTime, timeZone).utc().format("YYYY-MM-DD HH:mm:ss");
};

// date1이 date2 이전 날짜인지 체크
export const isBeforeDate = (
  date1: string | Date | Dayjs | null,
  date2: string | Date | Dayjs | null
) => {
  if (!date1 || !date2) return false;
  return dayjs(date1).isBefore(dayjs(date2));
};

// date1이 date2 이후 날짜인지 체크
export const isAfterDate = (
  date1: string | Date | Dayjs | null,
  date2: string | Date | Dayjs | null
) => {
  if (!date1 || !date2) return false;
  return dayjs(date1).isAfter(dayjs(date2));
};
