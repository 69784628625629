import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import i18n from "../locales/i18n";

abstract class _HttpClient {
  protected readonly instance: AxiosInstance;

  public constructor(url?: string) {
    const baseURL = url ? url : `${window.location.origin}`;

    this.instance = axios.create({
      baseURL,
    });
    this.instance.defaults.withCredentials = true;
  }
}

export default class HttpClient extends _HttpClient {
  public constructor(url?: string) {
    // const storedLocale = localStorage.getItem("locale");
    // const defaultLocale = i18n.language || "ko";
    // this.locale = storedLocale || defaultLocale;

    super(url);

    // 로컬스토리지에 locale이 없다면 기본값을 저장
    // if (!storedLocale) {
    //   localStorage.setItem("locale", this.locale);
    // }

    this.instance.interceptors.request.use(this.requestInterceptor, (error) =>
      Promise.reject(error)
    );
    this.instance.interceptors.response.use(this.responseInterceptor, (error) =>
      Promise.reject(error)
    );
  }

  private requestInterceptor = async (config: AxiosRequestConfig) => {
    let idTokenObj;
    let refreshTokenObj;
    const dynamicLocale =
      localStorage.getItem("locale") || i18n.language || "ko"; // Fetch updated locale
    for (var key in localStorage) {
      if (key === "accessToken") {
        idTokenObj = localStorage.getItem(key);
        break;
      }
    }
    for (var refreshTokenKey in localStorage) {
      if (refreshTokenKey === "refreshToken") {
        refreshTokenObj = localStorage.getItem(refreshTokenKey);
        break;
      }
    }
    if (idTokenObj) {
      this.setHeaders(config, "accessToken", idTokenObj);
    }
    if (refreshTokenObj) {
      this.setHeaders(config, "Refresh-Token", refreshTokenObj);
    }
    this.setHeaders(config, "Accept-Language", dynamicLocale);

    return config;
  };

  private responseInterceptor = (response: AxiosResponse) => {
    const newAccessToken = response.headers["new-access-token"];
    if (newAccessToken) {
      localStorage.setItem("accessToken", newAccessToken);
    }
    return response;
  };

  setHeaders(config: AxiosRequestConfig, type: string, token: string) {
    if (config.headers !== undefined) {
      if (type === "accessToken") {
        config.headers["Authorization"] = `Bearer ${token}`;
      } else {
        config.headers[type] = `${token}`;
      }
    }
  }

  post<Request, Response = any>(
    url: string,
    data?: Request | Request[],
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<Response>> {
    return this.instance.post(url, data, config);
  }

  get<Response = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<Response>> {
    return this.instance.get(url, config);
  }

  put<Request, Response = any>(
    url: string,
    data?: Request,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<Response>> {
    return this.instance.put(url, data, config);
  }

  delete<Response = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<Response>> {
    return this.instance.delete(url, config);
  }

  patch<Request, Response = any>(
    url: string,
    data?: Request,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<Response>> {
    return this.instance.patch(url, data, config);
  }
}
