export enum Urls {
  password = "/password",
  login = "/login",
  // 조회
  leadList = "/list",
  my = "/list/my",
  myBranch = "/list/mybranch",
  new = "/list/new",
  followUp = "/list/followup",
  myComment = "/list/myComment",
  leadDetail = "/list/detail/:id",

  salesActivity = "/list/salesActivity",

  intermediary = "/list/intermediary",
  intermediaryDetail = "/list/intermediary/detail/:intermediaryId",
  intermediaryAdd = "/list/intermediary/add",
  intermediaryUpdate = "/list/intermediary/update/:intermediaryId",

  noti = "/list/noti",
  // 등록
  registerByCase = "/register/edit",
  registerWithExcel = "/register/excel",

  // 게시판
  notice = "/notice",
  noticeDetail = "/notice/detail/:noticeId",
  noticeAdd = "/notice/add",
  noticeUpdate = "/notice/update/:noticeId",
  qna = "/qna",
  qnaDetail = "/qna/detail/:qnaId",
  qnaAdd = "/qna/add",
  qnaUpdate = "/qna/update/:qnaId",

  schedule = "/schedule",

  report = "/report",

  // 조달검사검수
  qaqc = "/qaqc",

  // 설정
  myInfo = "/myInfo",

  // 관리자 페이지
  adminPermission = "/admin/permission",
  // adminUser = "/admin/user",
  // adminGroupPermission = "/admin/grouppermission",
  adminGroupPermissionDetail = "/admin/grouppermission/detail/:id?",
  adminInformation = "/admin/information",
  adminProductRegister = "/admin/ProductRegister",
  adminSalesProtection = "/admin/salesprotection",

  // 대시보드
  dashBoard = "/",
}

export interface MenuItem {
  key: string;
  label: string;
  children?: MenuItem[];
  toUrl?: string;
  external?: boolean;
  requiresAdmin?: boolean;
}
export type MenuList = MenuItem[];

export const commonMenuList: MenuList = [
  {
    key: "board",
    label: "Board",
    children: [
      { key: "notice", label: "Notice & Board", toUrl: Urls.notice },
      { key: "qna", label: "Q&A", toUrl: Urls.qna },
      { key: "schedule", label: "Schedule", toUrl: Urls.schedule },
      { key: "qaqc", label: "Procurement", toUrl: Urls.qaqc },
    ],
  },
  {
    key: "list",
    label: "List",
    children: [
      { key: "leadList", label: "Lead", toUrl: Urls.leadList },
      {
        key: "salesActivity",
        label: "SalesActivity",
        toUrl: Urls.salesActivity,
      },
      { key: "intermediary", label: "Intermediary", toUrl: Urls.intermediary },
      { key: "myComment", label: "My Comment", toUrl: Urls.myComment },
    ],
  },
  {
    key: "report",
    label: "status",
    children: [{ key: "report", label: "Report", toUrl: Urls.report }],
    requiresAdmin: false,
  },
  {
    key: "sitelink",
    label: "Work Sites",
    children: [
      {
        key: "groupware",
        label: "Groupware",
        toUrl: "https://groupware.weareinbody.com/",
        external: true,
      },
      {
        key: "mail",
        label: "Mail",
        toUrl: "https://outlook.office.com/mail/",
        external: true,
      },
      {
        key: "erp",
        label: "ERP",
        toUrl: "https://inbody.operations.dynamics.com/",
        external: true,
      },
      {
        key: "order",
        label: "Product Orders",
        toUrl: "https://order.weareinbody.com/",
        external: true,
      },
      {
        key: "crm",
        label: "CRM",
        toUrl: "https://crm.weareinbody.com/",
        external: true,
      },
      {
        key: "mar",
        label: "MAR",
        toUrl: "https://mar.weareinbody.com/",
        external: true,
      },
      {
        key: "library",
        label: "Image Pool",
        toUrl: "https://lib.weareinbody.com/",
        external: true,
      },
      {
        key: "certification",
        label: "Certification",
        toUrl: "https://inbodykr.sharepoint.com/sites/certification2",
        external: true,
      },
      {
        key: "homepage",
        label: "InBody Website",
        toUrl: "https://www.inbody.co.kr/",
        external: true,
      },
      {
        key: "store",
        label: "InBody E-commerce",
        toUrl: "https://store.inbody.com/",
        external: true,
      },
      {
        key: "gm",
        label: "InBody GM",
        toUrl: "https://gm.weareinbody.com/",
        external: true,
      },
    ],
  },
];

export const userSettingMenuList: MenuList = [
  {
    key: "userSettings",
    label: "Settings",
    children: [{ key: "myInfo", label: "My Info", toUrl: Urls.myInfo }],
  },
];

export const managerSettingMenuList: MenuList = [
  {
    key: "managerSettings",
    label: "Settings",
    children: [
      { key: "myInfo", label: "My Info", toUrl: Urls.myInfo },
      {
        key: "adminPermission",
        label: "Permission",
        toUrl: Urls.adminPermission,
        requiresAdmin: true,
      },
    ],
  },
];

export const adminSettingMenuList: MenuList = [
  {
    key: "adminSettings",
    label: "Settings",
    children: [
      { key: "myInfo", label: "My Info", toUrl: Urls.myInfo },
      {
        key: "adminPermission",
        label: "Permission",
        toUrl: Urls.adminPermission,
        requiresAdmin: true,
      },
      {
        key: "adminInformation",
        label: "Field Item List",
        toUrl: Urls.adminInformation,
        requiresAdmin: true,
      },
      {
        key: "adminProductRegister",
        label: "Product",
        toUrl: Urls.adminProductRegister,
        requiresAdmin: true,
      },
      {
        key: "adminSalesProtection",
        label: "Sales Protection",
        toUrl: Urls.adminSalesProtection,
        requiresAdmin: true,
      },
    ],
  },
];
