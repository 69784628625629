import { GlobalStyles } from "@mui/material";
import { Gray900 } from "./Color";

const baseStyle = {
  // 기본적인 HTML 요소들은 CssBaseline에서 처리되므로 삭제
  a: {
    textDecoration: "none",
    color: "inherit",
  },
  "ul, li, ol": {
    listStyle: "none",
  },
  "button, input, select": {
    margin: 0,
    padding: 0,
  },
  "*": {
    fontFamily: '"Noto Sans KR", "Noto Sans", sans-serif',
    textRendering: "optimizeLegibility",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    fontSize: "14px",
    fontWeight: 400,
    color: Gray900,
    margin: 0,
    padding: 0,
    "&::-webkit-scrollbar": {
      display: "block",
      width: "8px",
      height: "8px",
      borderRadius: "6px",
      backgroundColor: "rgba(255, 255, 255, 0.4)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      borderRadius: "6px",
    },
  },
  "img, video": {
    height: "auto",
    maxWidth: "100%",
  },
  iframe: {
    border: 0,
  },
  table: {
    color: Gray900,
    borderCollapse: "collapse",
    borderSpacing: 0,
  },
  "td, th": {
    padding: 0,
  },
  "@media (max-width: 600px)": {
    "*": {
      fontSize: "12px",
    },
  },
};

const GlobalStyle = () => <GlobalStyles styles={baseStyle} />;

export default GlobalStyle;
